<template>
  <p>
      A list of my notifications e.g someone has assigned x to you.., You have received a message from Y
      you have x tickets overdue.
  </p>
</template>

<script>
export default {
methods:{
  getView (){ this.$http.get('/ViewAccess/mynotifications')
        .then(() => { 
        })
        .catch(() => { 
        })},
},
created(){
  this.getView()
}
}
</script>

<style>

</style>